.App {
  text-align: center;
  
  .App-header {
    color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    background-color: white;
    p {
      margin: 0;
      padding: 0;
    }
  }
  
}